<template>
    <div class="mt-5 content">
        <router-link :to="`/active/help/${item.id}`"
                     v-for="(item,index) in resData" :key="index"
                     class="mb-4 content-list d-flex flex-column
                      justify-content-center bg-yellow-gradual-x">
            <div class="h4 mb-2">{{item.title}}</div>
        </router-link>
    </div>
</template>

<script>
    import $api from '@/api/help/index'
    export default {
        name: "index",
        data(){
            return {
                resData:[]
            }
        },
        mounted() {
            this.getarticle()
        },
        methods:{
            getarticle(){
                $api.getarticle().then(res =>{
                    if(res.code == 500)return
                    this.resData = res.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">
.content{
    width: 1200px;
    margin: 0 auto;
    &-list{
        position: relative;
        height: 80px;
        padding-left: 20px;
        transition:all .5s;
        &:hover{
            transform:scale(.98);
            &::before{
                background-color: var(--main-dark-brown);
            }
        }
        &::before{
            content: '';
            width: 4px;
            height: 80px;
            left: 0;
            background-color: var(--main-yellow);
            position: absolute;
        }
    }
}
</style>
